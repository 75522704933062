import { FC, useEffect, useState } from 'react'; 
import {
    Outlet
} from "react-router-dom";

const Download: FC = () => {  
   
    
    return (
        <> 
           <p>
                <strong>
                    No download for the moment
                </strong>
           </p>
        </>
    );
}

export default Download